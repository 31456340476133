// Import library functions for theme creation.

@import '../../../../../node_modules/@angular/material/theming';

@mixin candy-checkbox-typography($config) {
  .mat-checkbox {
    font-family: mat-font-family($config);
    margin: 0 8px;
  }
  .mat-checkbox-label {
    white-space: initial;
  }
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: initial;
  }
}
