@import '~@angular/material/theming';
@import 'src/styles/variables';

@mixin oph-mat-tab-nav-bar-theme($config) {
  .mat-tab-nav-bar {
    border-bottom: 1px solid $border-color;

    .mat-tab-link {
      color: rgba($black-color, 0.64);
      font-family: $bitter-font-family;
      font-size: 14px;
      min-width: unset;
      opacity: 1;

      &.mat-tab-label-active {
        color: $black-color;
        font-weight: bold;
      }
    }

    &:not(.default-class) {
      .mat-ink-bar {
        width: 24px !important;
        margin-left: 24px;
      }
    }

    .mat-ink-bar {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      height: 3px;
    }
  }
}
