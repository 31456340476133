@import '~@angular/material/theming';
@import 'src/styles/variables';

@mixin oph-mat-datepicker-theme($config) {
  .mat-datepicker-content {
    border-radius: 3px;
    box-shadow: 0 4px 10px 2px rgba(84, 84, 84, 0.08), 0 0 2px 1px rgba(0, 0, 0, 0.03);
    margin-top: 4px;
  }
}
