// Import library functions for theme creation.
@import '../../../../node_modules/@angular/material/theming';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin candy-matmenu-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // Define any styles affected by the theme.
  .candy-matmenu {
    // Use mat-color to extract individual colors from a palette.
    background-color: #f4f8f9;
    box-shadow: none;
    border-radius: 0 !important;
    margin-top: -2px;
    a {
      color: black;
      text-decoration: none;
    }
  }
}

@mixin candy-matmenu-typography($config) {
  .candy-matmenu {
    font: {
      family: mat-font-family($config, body-1);
      size: mat-font-size($config, body-1);
      weight: mat-font-weight($config, body-1);
    }
  }
}
