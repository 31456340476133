@import '~@angular/material/theming';
@import 'src/styles/variables';
@import 'mat-option';

@mixin oph-mat-select-theme($config) {
  .mat-select-value {
    font-size: 14px;
  }

  .mat-select-panel {
    background-color: $white-color;
    border-radius: 3px !important;
    box-shadow: 0 4px 10px 2px rgba(84, 84, 84, 0.08), 0 0 2px 1px rgba(0, 0, 0, 0.03) !important;
    padding: 8px 0 !important;

    @include oph-mat-option-theme($config);
  }
}
